import { isDefined } from "@clipboard-health/util-ts";
import { GET_AGENT_PROFILE_PATH, useAgentProfile } from "@src/appV2/Agents/api/useAgentProfile";
import { useQueryClient } from "@tanstack/react-query";

import { type Agent } from "../../types";
import { AgentPreferenceKey, type AgentPreferences } from "./types";
import { useAgentPreferencesMutation } from "./useAgentPreferencesMutation";

export function useAgentPreferences() {
  const queryClient = useQueryClient();

  const { data: agentProfile, isSuccess: isAgentSuccess } = useAgentProfile();

  const { mutateAsync: mutateAgentPreferences } = useAgentPreferencesMutation({
    onMutate: async (newAgentPreferences) => {
      await queryClient.cancelQueries([GET_AGENT_PROFILE_PATH]);

      const previousAgentProfile = queryClient.getQueryData<Agent | undefined>([
        GET_AGENT_PROFILE_PATH,
      ]);

      queryClient.setQueryData<Agent | undefined>([GET_AGENT_PROFILE_PATH], (oldAgentProfile) => {
        if (isDefined(oldAgentProfile)) {
          return {
            ...oldAgentProfile,
            preference: {
              ...oldAgentProfile.preference,
              [newAgentPreferences.key]: newAgentPreferences.value,
            },
          };
        }

        return oldAgentProfile;
      });

      return { previousAgentProfile };
    },
    onError: (_error, _newAgentPreferences, context) => {
      const agentContext = context as { previousAgentProfile: Agent | undefined };

      queryClient.setQueryData<Agent | undefined>(
        [GET_AGENT_PROFILE_PATH],
        agentContext.previousAgentProfile
      );
    },
    onSettled: () => {
      void queryClient.invalidateQueries({ queryKey: [GET_AGENT_PROFILE_PATH] });
    },
  });

  function setFilters(formData: AgentPreferences) {
    // Save agent preferences if they changed
    if (formData.distance !== agentProfile?.preference.distance) {
      void mutateAgentPreferences({
        key: AgentPreferenceKey.DISTANCE,
        value: formData.distance,
      });
    }

    if (formData.license !== agentProfile?.preference.qualification) {
      void mutateAgentPreferences({
        key: AgentPreferenceKey.QUALIFICATION,
        value: formData.license,
      });
    }
  }

  return {
    filters: isAgentSuccess
      ? {
          distance: agentProfile.preference.distance,
          license: agentProfile.preference.qualification,
        }
      : undefined,
    setFilters,
  };
}
