import { get } from "@src/appV2/api/api";
import { environmentConfig } from "@src/appV2/environment";
import { APP_V2_APP_EVENTS } from "@src/appV2/lib/analytics";
import { useQuery, type UseQueryOptions, type UseQueryResult } from "@tanstack/react-query";

import { type Agent, agentProfileResponseSchema } from "../types";

export const GET_AGENT_PROFILE_PATH = "/agentProfile";

export function useAgentProfile(options: UseQueryOptions<Agent> = {}): UseQueryResult<Agent> {
  return useQuery({
    queryKey: [GET_AGENT_PROFILE_PATH],
    queryFn: async () => {
      const response = await get({
        url: `${environmentConfig.REACT_APP_BASE_API_URL}${GET_AGENT_PROFILE_PATH}`,
        responseSchema: agentProfileResponseSchema,
      });
      return response.data;
    },
    meta: {
      logErrorMessage: APP_V2_APP_EVENTS.GET_AGENT_PROFILE_FAILURE,
    },
    ...options,
  });
}
